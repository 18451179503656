const OptionsReenrolment = {
  REMATRICULATED: { 
    id: 0, 
    name: 'Rematriculado' 
  },

  TOREMATRICULATE: { 
    id: 1,
    name: 'Rematricular' 
  },

  NOREGISTRATION: { 
    id: 2,
    name: 'Sem Matricula' 
  }
}

Object.freeze(OptionsReenrolment)

export default OptionsReenrolment